<template>
    <div>
        <el-card>
            <el-form :model="MemberInfo" :rules="MemberInfoRules" ref="MemberInfoRef" label-width="80px"
            v-loading="Loading">
                <el-alert class="AddMember_Alert" title="家长信息" type="info" show-icon :closable="false"/>
                <el-form-item label="姓名" prop="Name">
                    <el-input class="AddMember_Input" placeholder="家长姓名" v-model="MemberInfo.Name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="Gender">
                    <el-radio-group class="AddMember_Radio" v-model="MemberInfo.Gender">
                        <el-radio label="男" border></el-radio>
                        <el-radio label="女" border></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="电话" prop="Phone">
                    <el-input class="AddMember_Input" placeholder="联系电话" v-model="MemberInfo.Phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="Email">
                    <el-input class="AddMember_Input" placeholder="邮箱" v-model="MemberInfo.Email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="微信" prop="WeChat">
                    <el-input class="AddMember_Input" placeholder="微信号" v-model="MemberInfo.WeChat" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="QQ" prop="QQ">
                    <el-input class="AddMember_Input" placeholder="QQ" v-model="MemberInfo.QQ" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="关系" prop="RelationShip">
                    <el-select class="AddMember_Select" v-model="MemberInfo.RelationShip">
                        <el-option
                        v-for="item in RelationShip"
                        :key="item"
                        :label="item"
                        :value="item">
                            <span class="AddUser_Span_Title">{{ item }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="城市">
                    <el-col :span="6">
                        <el-cascader class="AddMember_Cascader" :options="Options" v-model="SelectedOptions" @change="SelectedCityHandleChange"></el-cascader>
                    </el-col>
                </el-form-item>
                <el-form-item label="地址" prop="Address">
                    <el-input class="AddMember_Input" placeholder="家庭地址" v-model="MemberInfo.Address" autocomplete="off"></el-input>
                </el-form-item>
                <el-alert class="AddMember_Alert" title="学生信息" type="info" show-icon :closable="false"/>
                <div v-for="(item, index) in MemberInfo.Students" :key="item.key">
                    <el-card shadow="always" class="AddStudentItem_Card">
                        <el-form-item  label="名称" :prop="'Students.' + index +'.Stu_Name'" :rules="MemberInfoRules.Stu_Name">
                            <el-input class="AddMember_Input"  v-model="item.Stu_Name" placeholder="请输入学生名称" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item  label="性别" :prop="'Students.' + index +'.Stu_Gender'" :rules="MemberInfoRules.Stu_Gender">
                            <el-radio-group class="AddMember_Radio" v-model="item.Stu_Gender">
                                <el-radio label="男" border></el-radio>
                                <el-radio label="女" border></el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="年龄" :prop="'Students.' + index +'.Stu_Age'" :rules="MemberInfoRules.Stu_Age">
                            <el-input-number class="AddMember_InputNumber" v-model="item.Stu_Age" :precision="0" :step="1" :min="2" :max="18"></el-input-number>
                        </el-form-item>
                        <el-form-item  label="生日" :prop="'Students.' + index +'.Stu_Birthday'">
                            <el-date-picker class="AddMember_DatePicker" type="date" 
                                placeholder="选择日期"  
                                format="yyyy 年 MM 月 dd 日" 
                                value-format="yyyy-MM-dd" 
                                v-model="item.Stu_Birthday">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item  label="就读学校" :prop="'Students.' + index +'.Stu_CurrentSchool'">
                            <el-input class="AddMember_Input"  v-model="item.Stu_CurrentSchool" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="学校" :prop="'Students.' + index +'.Stu_SchoolID'" :rules="MemberInfoRules.Stu_SchoolID">
                            <el-select class="AddStudentItem_Select" v-model="item.Stu_SchoolID" placeholder="请选择学校">
                                <el-option
                                v-for="item in SchoolList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                    <span class="AddStudednt_Span_Title">{{ item.name }}</span>
                                    <span class="AddStudednt_Span_Lable">{{ item.address }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item  label="会员标志" :prop="'Students.' + index +'.Stu_Membership'" :rules="MemberInfoRules.Stu_Membership">
                            <el-radio-group class="AddMember_Radio" v-model="item.Stu_Membership">
                                <el-radio :label=1 border>会员</el-radio>
                                <el-radio :label=0 border>非会员</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-button class="DeleteStudentItem__Button" type="danger" icon="el-icon-delete" @click="DeleteStudentItem(index)" circle></el-button>
                    </el-card>
                </div>
                <el-button class="AddMember_Button" type="text" icon="el-icon-plus" @click="AddStudentItem">追加学生</el-button>
                <el-alert class="AddMember_Alert" title="其他信息" type="info" show-icon :closable="false"/>
                <el-form-item label="评级" prop="Scoring">
                    <el-rate class="AddMember_Rate" v-model="MemberInfo.Scoring"></el-rate>
                </el-form-item>
                <el-form-item label="来源" prop="SealTypeID">
                    <el-select class="AddMember_Select" v-model="MemberInfo.SealTypeID" placeholder="请选择来源类型">
                        <el-option
                        v-for="item in SealTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                            <span class="AddStudednt_Span_Title">{{ item.name }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="市场" prop="ProviderID">
                    <el-select class="AddMember_Select" v-model="MemberInfo.ProviderID" placeholder="请选择用户">
                        <el-option
                        v-for="item in EmployeeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                            <span class="AddStudednt_Span_Title">{{ item.name }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="顾问" prop="ConsultantID">
                    <el-select class="AddMember_Select" v-model="MemberInfo.ConsultantID" placeholder="请选择用户">
                        <el-option
                        v-for="item in EmployeeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                            <span class="AddStudednt_Span_Title">{{ item.name }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-button class="AddMember_Button" type="primary" icon="el-icon-upload" @click="_PostMember" :disabled="Loading">提交</el-button>
        </el-card>
    </div>
</template>

<script>
import { provinceAndCityData, CodeToText,TextToCode } from 'element-china-area-data'
import { RELATIONSHIP } from '@/assets/js/systemvariable.js' 
import { GetSchools } from '@/assets/js/api/schoolapi' 
import { PostMember } from '@/assets/js/api/memberapi'
import { GetEmployeeList } from '@/assets/js/api/userapi' 
import { GetSealTypeList } from '@/assets/js/api/sealtypeapi'
import { StringisNull } from '@/assets/js/common.js' 
export default {
    data(){
        return{
            Options: provinceAndCityData,
            SelectedOptions:[],
            MemberInfo:{
                Name:'',
                Gender:"男",
                Phone:'',
                Email:'',
                WeChat:'',
                QQ:'',
                RelationShip:"爸爸",
                Province:"山东省",
                City:"潍坊市",
                Address:'',
                Students:[
                    {
                        Stu_Name:'',
                        Stu_Gender:"男",
                        Stu_Age:2,
                        Stu_Birthday:'',
                        Stu_CurrentSchool:'',
                        Stu_SchoolID:'',
                        Stu_ClassID:'',
                        Stu_Membership:0
                    }
                ],
                Scoring:1,
                ProviderID:'',
                ConsultantID:'',
                SealTypeID:''
            },
            MemberInfoRules:{
                Name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                Gender: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                RelationShip: [
                    { required: true, message: '请选择关系', trigger: 'change' }
                ],
                Address: [
                    { required: true, message: '请输入家庭地址', trigger: 'blur' }
                ],
                Stu_Name: [
                    { required: true, message: '请输入学生姓名', trigger: 'blur' }
                ],
                Stu_Gender: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                Stu_Age: [
                    { required: true, message: '请输入年龄', trigger: 'blur' }
                ],
                Stu_SchoolID: [
                    { required: true, message: '请选择学校', trigger: 'change' }
                ],
                SealTypeID: [
                    { required: false, message: '请选择来源', trigger: 'change' }
                ],
                Stu_Membership: [
                    { required: true, message: '请选择会员标志', trigger: 'change' }
                ]
            },
            RelationShip:RELATIONSHIP,
            SchoolList:[],
            EmployeeList:[],
            SealTypeList:[],
            ClLoading:true,
            SeLoading:true,
            ScLoading:true,
            Loading:false
        }
    },
    watch:{
        // ClLoading: function(newval){
        //     if(newval == false && this.PoLoading == false && this.ScLoading == false){
        //         this.Loading = false
        //     }
        // },
        // SeLoading: function(newval){
        //     if(newval == false && this.ClLoading == false && this.ScLoading == false){
        //         this.Loading = false
        //     }
        // },
        // ScLoading: function(newval){
        //     if(newval == false && this.ClLoading == false && this.SeLoading == false){
        //         this.Loading = false
        //     }
        // },
    },
    created(){
        this.GetParams()
        this._GetSchools()
        this._GetEmployeeList()
        this._GetSealTypeList()
    },
    methods:{
        GetParams(){
            this.SelectedOptions = [TextToCode["山东省"].code.toString(),TextToCode["山东省"]["潍坊市"].code.toString()]
        },
        SelectedCityHandleChange(value) {
            this.MemberInfo.Province = CodeToText[value[0]]
            this.MemberInfo.City = CodeToText[value[1]]
        },
        AddStudentItem() {
            this.MemberInfo.Students.push({
                Stu_Name:"",
                Stu_Gender:"男",
                Stu_Age:2,
                Stu_Birthday:"",
                Stu_CurrentSchool:"",
                Stu_SchoolID:"",
                Stu_ClassID:""
          })
        },
        DeleteStudentItem(index){
            this.MemberInfo.Students.splice(index,1);
        },
        async _GetSchools(){
            this.ScLoading = true
            this.SchoolList = await GetSchools()
            this.ScLoading = false
            if(this.SchoolList == null){
                this.$message.error('获取学校列表失败');
            }
        },
        async _GetEmployeeList(){
            this.ClLoading = true
            var obj = await GetEmployeeList();
            this.ClLoading = false
            this.EmployeeList = obj.users
            if(this.EmployeeList == null){
                this.$message.error('获取员工列表失败');
            }
        },
        async _GetSealTypeList(){
            this.SeLoading = true
            this.SealTypeList = await GetSealTypeList()
            this.SeLoading = false
            if(this.SchoolList == null){
                this.$message.error('获取销售来源类型列表失败');
            }
        },
        async _PostMember(){
            this.$refs.MemberInfoRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            if(StringisNull(this.MemberInfo.QQ) === true)
            {
                this.MemberInfo.QQ = 0
            }
            else
            {
                this.MemberInfo.QQ  = parseInt(this.MemberInfo.QQ)                
            }

            if(StringisNull(this.MemberInfo.SealTypeID) === true)
            {
                this.MemberInfo.SealTypeID = 0
            }
            else
            {
                this.MemberInfo.SealTypeID  = parseInt(this.MemberInfo.SealTypeID)                
            }

            if(StringisNull(this.MemberInfo.ProviderID) === true)
            {
                this.MemberInfo.ProviderID = 0
            }
            else
            {
                this.MemberInfo.ProviderID  = parseInt(this.MemberInfo.ProviderID)                
            }
            if(StringisNull(this.MemberInfo.ConsultantID) === true)
            {
                this.MemberInfo.ConsultantID = 0
            }
            else
            {
                this.MemberInfo.ConsultantID  = parseInt(this.MemberInfo.ConsultantID)                
            }
            this.Loading = true
            var ret = await PostMember(this.MemberInfo)
            this.Loading = false
            if(ret.code == 1)
            {
                this.$refs.MemberInfoRef.resetFields();
                this.$message.success('提交成功');
                this.$router.push({
                    name:'MemberList'
                })
            }
            else
            {
                this.$message.error('提交失败');
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .AddMember_Alert{
        margin-bottom: 20px;
    }
    .AddMember_Input{
        width: 500px;
        display: flex;
    }
    .AddMember_Radio{
        display: flex;
    }
    .AddMember_Select{
        display:table
    }
    .AddMember_Cascader{
        width: auto;
        display: flex;
    }
    .AddMember_InputNumber{
        width: 200px;
        display: flex;
    }
    .AddMember_Rate{
        display: flex;
        margin: 10px
    }
    .AddMember_DatePicker{
        display: flex;
    }
    .AddMember_Button{
        display: flex;
        margin: 20px
    }
    .AddStudentItem_Card{
        margin: 10px;
    }
    .DeleteStudentItem__Button{
        display: flex;
        margin: 5px;
    }
    .AddStudentItem_Select{
        display:table
    }
    .AddStudednt_Span_Title{
        float: left
    }
    .AddStudednt_Span_Lable{
        float: right; 
        color: #8492a6; 
        font-size: 13px
    }
</style>