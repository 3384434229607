/**
 * 检查String是否为空
 * @param strings
 */
export function StringisNull(strings){
    if (strings.replace(/(^s*)|(s*$)/g, "").length ==0) 
    { 
        return true
    }
    return false
}
/**
 * 返回时间(HH:mm) 24小时制
 * @param datetime
 */
export function Conver2Time(datetime){
    var hour = datetime.getHours()
    hour = hour < 10 ? '0' + hour : hour;
    var min = datetime.getMinutes()
    min = min < 10 ? '0' + min : min;
    return (hour + ':' + min)
}
/**
 * 返回日期加时间 （yyyy-MM-dd HH:mm:ss）
 * @param datetime
 */
export function Conver2DateTime(datetime){
    var year=datetime.getFullYear();
    var month=change(datetime.getMonth()+1);
    var day=change(datetime.getDate());
    var hour=change(datetime.getHours());
    var minute=change(datetime.getMinutes());
    var second=change(datetime.getSeconds());
    function change(t){
        if(t<10){
          return "0"+t;
        }else{
          return t;
        }
    }
    var time=year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
    return time
}
/**
 * 遍历列的所有内容，获取最宽一列的宽度
 * @param arr
 */
export function  GetMaxLength (arr) {
    return arr.reduce((acc, item) => {
    if (item) {
        const calcLen = GetTextWidth(item)
        if (acc < calcLen) {
        acc = calcLen
        }
    }
        return acc
    }, 0)
}
/**
 * 使用span标签包裹内容，然后计算span的宽度 width： px
 * @param str
 */
export function GetTextWidth (str) {
    let width = 0
    const html = document.createElement('span')
    html.innerText = str
    html.className = 'getTextWidth'
    document.querySelector('body').appendChild(html)
    width = document.querySelector('.getTextWidth').offsetWidth
    document.querySelector('.getTextWidth').remove()
    return width
}
/**
 * 获取GUID
 */
export function GetGUID(){
    var len=32;      //32长度
        var radix=16;    //16进制
        var chars='0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
        var uuid=[],i;
        radix=radix || chars.length;
        if(len){
            for(i=0;i<len;i++){
                uuid[i]=chars[0 | Math.random()*radix];
            }
        }else{
            var r;
            uuid[8]=uuid[13]=uuid[18]=uuid[23]='-';
            uuid[14]='4';
            for(i=0;i<36;i++){
                if(!uuid[i]){
                    r=0 | Math.random()*16;
                    uuid[i]=chars[(i==19)?(r&0x3)|0x8:r];
                }
            }
        }
    return uuid.join('');
}